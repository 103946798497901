/* global styles */
/*@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";*/

@import "~chartist/dist/chartist.min.css";
@import "~angular-calendar/css/angular-calendar.css";
@import "~dragula/dist/dragula.min.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";

html {
  scroll-behavior: smooth !important;
}
body{
  scroll-behavior: smooth !important;
  overscroll-behavior-y: none !important;
}

@font-face {
  font-family: 'mv_waheedregular';
  src: url('assets/fonts/mv_waheed_1-webfont.woff2') format('woff2'),
  url('assets/fonts/mv_waheed_1-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.dhv {
  font-family: 'MV_Faseyha';
  font-size: 20px;
  text-align: right;
  line-height: 36px;
  direction: rtl;

    font-family: 'MV Faseyha';
    src: url("/assetsfonts/mv_faseyha.eot");
    src: url("/assets/fonts/mv_faseyha.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/mv_faseyha.woff2") format("woff2"),
    url("/assets/fonts/mv_faseyha.woff") format("woff"),
    url("/assets/fonts/mv_faseyha.ttf") format("truetype"),
    url("/assets/fonts/mv_faseyha.svg#mv_faseyharegular") format("svg");
    font-weight: normal;
    font-style: normal;
}
.dhv_nosize {
  font-family: 'MV_Faseyha';
  text-align: right;
  direction: rtl;
  padding-right:10px;

    font-family: 'MV Faseyha';
    src: url("/assetsfonts/mv_faseyha.eot");
    src: url("/assets/fonts/mv_faseyha.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/mv_faseyha.woff2") format("woff2"),
    url("/assets/fonts/mv_faseyha.woff") format("woff"),
    url("/assets/fonts/mv_faseyha.ttf") format("truetype"),
    url("/assets/fonts/mv_faseyha.svg#mv_faseyharegular") format("svg");
    font-weight: normal;
    font-style: normal;
}
.dhv-title {
  font-family: 'MV_Faseyha';
  font-size: 26px;
  line-height: 32px;
  text-align: right;
  direction: rtl;

    font-family: 'MV Faseyha';
    src: url("/assetsfonts/mv_faseyha.eot");
    src: url("/assets/fonts/mv_faseyha.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/mv_faseyha.woff2") format("woff2"),
    url("/assets/fonts/mv_faseyha.woff") format("woff"),
    url("/assets/fonts/mv_faseyha.ttf") format("truetype"),
    url("/assets/fonts/mv_faseyha.svg#mv_faseyharegular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MV Faseyha';
    src: url("/assetsfonts/mv_faseyha.eot");
    src: url("/assets/fonts/mv_faseyha.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/mv_faseyha.woff2") format("woff2"),
    url("/assets/fonts/mv_faseyha.woff") format("woff"),
    url("/assets/fonts/mv_faseyha.ttf") format("truetype"),
    url("/assets/fonts/mv_faseyha.svg#mv_faseyharegular") format("svg");
    font-weight: normal;
    font-style: normal;
}

::selection { background-color: rgba(127, 255, 212, 0.6); }
::-moz-selection { background-color: rgba(127, 255, 212, 0.6); }



.mat-row:hover{
  background-color: rgba(97, 97, 97, 0.068);

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}



.ngx-contextmenu .dropdown-menu {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border: solid 1px #cccccc;
  background-color: #ffffff;
  padding: 0;
  border-radius: 5px;
}
.ngx-contextmenu li {
  display: block;
  border-top: solid 1px #f1f1f1;
  text-align: left;
  cursor: pointer;
}
.ngx-contextmenu li:first-child {
  border-top:none;
}
.ngx-contextmenu a {
  /* color: rgba(0, 0, 0, 0.5);
  display: block;
  font-size: 14px;
  font-weight: 300; */
}
.ngx-contextmenu a:hover {
  /*olor: rgba(0, 0, 0, 0.9);
  background-color: rgba(0, 0, 0, 0.1)*/
}
